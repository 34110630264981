(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('minmax', hippoMinmaxDirective);
    hippoMinmaxDirective.$inject = ['$timeout', '$parse'];
    function hippoMinmaxDirective ($timeout, $parse) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function ($scope, $element, $attrs, ngModelCtrl) {
                // console.log('hippoMinmaxDirective');
                var min, max;
                var step = 1;
                $attrs.$observe('min', function (val) { min = (val.trim()) === '' ? NaN : Number(val); });
                $attrs.$observe('max', function (val) { max = (val.trim()) === '' ? NaN : Number(val); });
                $attrs.$observe('step', function (val) { step = (val.trim() === '' || !Number(val)) ? 1 : Number(val); });

                var up = function () {
                    // console.log('up', min, max, step);
                    var val = ngModelCtrl.$modelValue;
                    if (isNaN(val)) return;

                    var newVal = Math.round((val + step) / step) / (1 / step);
                    if (!isNaN(max)) newVal = Math.min(newVal, max);
                    updateView(newVal);
                };
                var down = function () {
                    var val = ngModelCtrl.$modelValue;
                    if (isNaN(val)) return;

                    var newVal = Math.round((val - step) / step) / (1 / step);
                    if (!isNaN(min)) newVal = Math.max(newVal, min);
                    updateView(newVal);
                };
                var valMinMax = function () {
                    var newVal = ngModelCtrl.$modelValue;
                    if (newVal === '' || isNaN(newVal)) return updateView(/* undefined */);

                    if (newVal !== min && newVal !== max) newVal = Math.round(newVal / step) * step;
                    if (!isNaN(min)) newVal = Math.max(newVal, min);
                    if (!isNaN(max)) newVal = Math.min(newVal, max);
                    updateView(newVal);
                };

                $element.bind('keydown keypress', function (event) {
                    if (event.which === 38) { // up
                        up();
                        event.preventDefault();
                    } else if (event.which === 40) { // down
                        down();
                        event.preventDefault();
                    }
                });

                $element.bind('blur', function () {
                    valMinMax();
                });

                function updateView (value) {
                    ngModelCtrl.$setViewValue(value);
                    ngModelCtrl.$render();
                }
            }
        };
    }
})(window.angular, window._);
